
  .spacer {
    flex: 1;
  }

  
  .content {
    display: flex;
    margin: 82px auto 32px;
    padding: 0 16px;
    max-width: 960px;
    flex-direction: column;
    align-items: center;
  }


  .debug{
    border: solid 1px red;
  }

  .sticky {
    background: #fff;
    box-shadow: 0 3px 12px 0 rgba(0,0,0,.08);
    position: fixed;
    top: 0;
    height: 120px;
    width: 100%;
}
.navbar.bg-dark{
  background-color: rgba(52,58,64,0.9) !important;
}
.navbar{
    height: 100px;
    opacity: 0.8;

    .navbar-collapse{
        justify-content: center;

        .navbar-nav{
            font-size: 22px;
        }

        .nav-link.active {
            color: #E15D2C !important;
          }
    
        .separator{
            color: #FFFFFF;
            font-size: 14px;
            margin: auto 20px;        
            opacity: 0.5;
        }
    }
}



  .title{
    font-family: 'Cormorant+Garamond';
    font-stretch: extra-expanded;
    color: #4a4a4a;
  }

 

  .section-intro{
    background-color: #F2F2F2;
    padding: 140px 0;
    text-align: center;
    a,
    a:visited {
      color: rgba(123, 123, 123, 1) !important;
      text-transform: uppercase;
    }
    a:hover {
      color: rgba(123, 123, 123, 0.5) !important;
      text-decoration: underline;
    }
  

    .col{
      margin: 0 25px;
    }

    .col-story{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .story{
        color: rgb(123, 123, 123);
        text-align: justify;
        margin: 30px 50px 10px 50px;
        transition: all 0.2s ease-in-out;
      }
    }

    .photo-box{

      margin: 20px auto;
      width: 550px;
      height: 550px;
      border: 10px solid #FFFFFF;
      background-image: url('/assets/img/massaman.jpg');
      background-color: #cccccc;
      background-position: center; 
      background-repeat: no-repeat; 
      background-size: cover;
    }

  }

  .section-photos{
    padding-top: 100px;
    //maheight: 600px;
    background-image: url('/assets/img/background1.jpg');
    background-color: #cccccc;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;

    .container{
      line-height: 0;
      -webkit-column-count: 4;
      -webkit-column-gap:   0px;
      -moz-column-count:    4;
      -moz-column-gap:      0px;
      column-count:         4;
      column-gap:           15px;
    }

    img {
      /* Just in case there are inline attributes */
      width: 100% !important;
      height: auto !important;
      margin: 10px;
    }
  }




  

  footer{
    display: flex;
    align-items: center;
    line-height: 20px;

    color: #FFFFFF;
    background-color: #E15D2C;
    padding: 50px 50px;
    .footer-info{
      padding: 0 20px;
    }
    .goback{
      position: absolute;
      right: 30px;
    }

  }


  /* Responsive Styles */
  /* 1280-1024   - desktop (default grid) */
  @media all and (min-width: 1024px) and (max-width: 1280px) {
    .section-photos .container{

      -moz-column-count:    4;
      -webkit-column-count: 4;
      column-count:         4;
    }
  }

  /* 1024-768    - tablet landscape  */
  @media all and (min-width: 768px) and (max-width: 1024px) { 
    .section-photos .container {
      -moz-column-count:    3;
      -webkit-column-count: 3;
      column-count:         3;
      }
  }

  /* 768-480     - tablet  */
  @media all and (min-width: 480px) and (max-width: 768px) {
    .navbar{
      height: inherit;
    }
    .navbar-nav{
      -webkit-box-shadow: 6px 5px 23px -4px rgba(0,0,0,0.75);
      -moz-box-shadow: 6px 5px 23px -4px rgba(0,0,0,0.75);
      box-shadow: 6px 5px 23px -4px rgba(0,0,0,0.75);
      background-color: #000000;
      .separator{
        display: none;
      }
      .nav-link{
        margin-left: 20px;
        font-size: smaller;
        text-transform: uppercase;
      }
    }

    .carousel-caption{
      bottom: unset;
    }

    .section-photos .container {
      -moz-column-count:    2;
      -webkit-column-count: 2;
      column-count:         2;
      }
   }

  /* 480-less    - phone landscape & smaller  */
  @media all and (max-width: 480px) { 
    h5{
      font-size: smaller;
    }
    .navbar{
      height: inherit;
    }
    .navbar-nav{
      -webkit-box-shadow: 6px 5px 23px -4px rgba(0,0,0,0.75);
      -moz-box-shadow: 6px 5px 23px -4px rgba(0,0,0,0.75);
      box-shadow: 6px 5px 23px -4px rgba(0,0,0,0.75);
      background-color: #000000;
      .separator{
        display: none;
      }
      .nav-link{
        margin-left: 20px;
        font-size: smaller;
        text-transform: uppercase;
      }
    }

    .carousel-caption{
      bottom: unset;
    }

    .section-intro{
      padding: 40px 0;
      .col{
        margin: 0;
      }
      .col-story{
       
        .story{
          margin: 10px;
        }
      }
      .container{
        margin-top: 40px;
      }
      .photo-box{
        width: 300px;
        height: 300px;
      }
    }

    
    .section-photos{ 
      padding-top: 20px;
      .container {
        -moz-column-count:    2;
        -webkit-column-count: 2;
        column-count:         2;
      }
    }

    footer{
      font-size: 12px;
      .footer-info{
        padding: 0px;
      }
    }
  }

