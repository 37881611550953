/* You can add global styles to this file, and also import other style files */
@import "assets/theme/font";
@import "assets/theme/custom";

// primary font
$primary_font: 'Cormorant+Garamond';
// font-weight
$thin:100;
$light:300;
$regular:400;
$bold:700;
$black:900;
// Font size
$font-xs:12px;
$font-sm: 14px;
$primary-fs: 16px;
$font-md: 18px;
$font-lg: 20px;
$font-color: rgb(123, 123, 123);

body {
    font-family: $primary_font !important;
    font-size: $primary-fs;
    font-weight: $regular;
    color: $font-color;
}

:host {
    font-family:  $primary_font !important;
    font-size: 14px;
    color: rgb(123, 123, 123);
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0;
  }

  p {
    margin: 0;
  }


  a,
  a:visited {
    color: rgba(255, 255, 255, 0.7) !important;
    text-decoration: none !important;
  }

  a:hover {
    color: rgba(255, 255, 255, 0.5) !important;
    text-decoration: underline !important;
  }




.shadow1{
    -webkit-box-shadow: 6px 5px 23px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 5px 23px -4px rgba(0,0,0,0.75);
    box-shadow: 6px 5px 23px -4px rgba(0,0,0,0.75);
}


.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.black{
  color: rgb(30, 30, 30) !important;
}

.display-1{
  font-size: 5rem !important;
}